<template>
  <div class="vs-row">
    <template lang="html">
      <div>
        <vx-card title="Net Enquiry" title-color="primary" subtitle>
          <template>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Net Enquiry Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker placeholder="Select Date" v-model="enquiry_date"></datepicker>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Net Enquiry No</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="net_enquiry_no" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Name</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="name" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Mobile</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="mobile" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Email</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" type="email" v-model="email" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Details</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea class="w-full" type="email" v-model="details" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>City</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select v-model="selected_city" :options="cities"></v-select>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Select Spoc</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select v-model="selected_spoc" :options="spocs"></v-select>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-2/3 w-full ml-auto">
                <vs-button class="mr-3 mb-2" @click="submitNetEnquiry()">Submit</vs-button>
                <vs-button color="warning" type="border" class="mb-2" @click="clearAll()">Reset</vs-button>
              </div>
            </div>
          </template>
        </vx-card>
      </div>
    </template>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import constants from "../../constants.json";
import router from "@/router";
import moment from "moment";
import EventBus from "../components/eventbus.js";
import vSelect from "vue-select";
export default {
  mounted() {
    EventBus.$emit("unloadplus", true);
    // console.log(this.$acl.check("cm2"));
    // if (this.$acl.check("cm2")) {
    //   console.log("yes it is cm2");
    // } else {
    //   console.log("nothing");
    // }
    if (localStorage.getItem("role") !== "cm2") {
      if (localStorage.getItem("role") === "cm1") {
        router.push("/");
      } else if (localStorage.getItem("role") === "cm4") {
        router.push("/adminDashboard");
      }
    }
    this.getSpocs();
    /* let navbar = document.getElementsByClassName("vx-navbar-wrapper");
    navbar[0].style.display = "none"; */
  },
  data() {
    return {
      net_enquiry_no: "",
      name: "",
      mobile: "",
      email: "",
      enquiry_date: new Date(),
      selected_spoc: { label: "Spoc", value: "spoc" },
      cities: [],
      selected_city: "",
      spocs: [],
      details: "",
      spocscopy: []
    };
  },
  components: {
    Datepicker,
    "v-select": vSelect
  },
  watch: {
    selected_city: function(value) {
      this.spocs = [];
      this.spocscopy.forEach(spoc => {
        if (spoc.city === value.label) {
          this.spocs.push(spoc);
        }
      });
      this.selected_spoc = this.spocs[0];
    }
  },
  methods: {
    getSpocs() {
      axios
        .get(`${constants.SERVER_API}getSpocs`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.cities = [];
          var unique = response.data.spocs
            .map(name => {
              return {
                count: 1,
                name: name.city
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.cities.push({
                label: key,
                value: key
              });
            }
          }
          for (var i = 0; i < response.data.spocs.length; i++) {
            var option = {
              label: response.data.spocs[i].first_name,
              value: response.data.spocs[i].id,
              city: response.data.spocs[i].city
            };
            this.spocscopy.push(option);
          }
          this.selected_city = this.cities[0];
          // this.selected_spoc = this.spocs[0];
        })
        .catch(error => {
          if (error.response) {
            console.log("error in getSpocs", error.response);
            if (error.response.status === 401) {
              this.ReDirect_to_login();
            }
          }
        });
    },
    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    clearAll() {
      this.net_enquiry_no = "";
      this.enquiry_date = new Date();
      this.name = "";
      this.mobile = "";
      this.email = "";
      this.details = "";
      this.selected_city = this.cities[0];
    },
    showWarningNotification(text) {
      this.$vs.notify({
        title: "Warning",
        text: text,
        color: "warning",
        position: "top-right"
      });
    },
    submitNetEnquiry() {
      //validation
      if (this.net_enquiry_no == "") {
        this.showWarningNotification("Net Enquiry cannot be empty");
        return;
      }

      if (this.mobile == "") {
        this.showWarningNotification("Mobile cannot be empty");
        return;
      }

      if (this.selected_spoc == null) {
        this.showWarningNotification("Select at least one Spoc");
        return;
      }

      let params = {
        enquiry_date: moment(this.enquiry_date).format("DD-MMM-YYYY"),
        net_enquiry_no: this.net_enquiry_no,
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        city: this.selected_city.value,
        details: this.details,
        selected_spoc: this.selected_spoc.value
      };
      console.log("net enquiry values", params);
      axios
        .post(`${constants.SERVER_API}addNetEnquiry`, params, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          let color = "";
          let title = "";
          let text = "";
          console.log(response);
          if (response.data.status === "success") {
            color = "success";
            title = "Success";
            text = "Successfully Added Net Enquiry";
            this.clearAll();
          } else {
            color = "danger";
            title = response.data.code;
            text = response.data.message;
          }
          this.$vs.notify({
            color: color,
            title: title,
            text: text
          });
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  beforeDestroy() {
    /* let navbar = document.getElementsByClassName("vx-navbar-wrapper");
    navbar[0].style.display = ""; */
  }
};
</script>

<style>
</style>
